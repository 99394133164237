<template>
    <div class="learning">
        <transition name="fade-transform" mode="out-in">
            <router-view :key="$route.fullPath"></router-view>
        </transition>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    watch:{
        $route(v){
            document.documentElement.scrollTop = 0
        }
    },
}
</script>
<style scoped lang="less">
.learning{
    flex: 1;
    display: flex;
    flex-direction: column;
}
</style>